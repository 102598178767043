import {Injectable, makeStateKey, TransferState} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Email } from '../components/layouts/footer/footer.component';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Message } from '../components/pages/contactus/Model/message';
import { catchError, tap } from 'rxjs/operators';

const SUBSCRIBE_EMAIL_KEY = makeStateKey<any>('subscribeEmail');
const MESSAGE_EMAIL_KEY = makeStateKey<any>('messageEmail');
const FAQ_EMAIL_KEY = makeStateKey<any>('faqEmail');

@Injectable({
  providedIn: 'root'
})
export class MailApiService {

  constructor(
    private httpClient: HttpClient,
    private transferState: TransferState,
  ) {}

  subscribeEmail(email: Email): Observable<any> {
    if (this.transferState.hasKey(SUBSCRIBE_EMAIL_KEY)) {
      const data = this.transferState.get(SUBSCRIBE_EMAIL_KEY, null);
      this.transferState.remove(SUBSCRIBE_EMAIL_KEY);
      return of(data);
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const requestModel = {
      endpoint: `/face/nologin/subscribe-email`,
      method: 'POST',
      body: email,
      params: null
    };

    return this.httpClient.post<any>(`${environment.bffUrl}/data/nologin`, requestModel, { headers })
      .pipe(
        tap(response => {
          this.transferState.set(SUBSCRIBE_EMAIL_KEY, response);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  messageEmail(mes: Message): Observable<any> {
    if (this.transferState.hasKey(MESSAGE_EMAIL_KEY)) {
      const data = this.transferState.get(MESSAGE_EMAIL_KEY, null);
      this.transferState.remove(MESSAGE_EMAIL_KEY);
      return of(data);
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const requestModel = {
      endpoint: `/face/nologin/message-email`,
      method: 'POST',
      body: mes,
      params: null
    };

    return this.httpClient.post<any>(`${environment.bffUrl}/data/nologin`, requestModel, { headers })
      .pipe(
        tap(response => {
          this.transferState.set(MESSAGE_EMAIL_KEY, response);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  faqEmail(mes: Message): Observable<any> {
    if (this.transferState.hasKey(FAQ_EMAIL_KEY)) {
      const data = this.transferState.get(FAQ_EMAIL_KEY, null);
      this.transferState.remove(FAQ_EMAIL_KEY);
      return of(data);
    }

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const requestModel = {
      endpoint: `/face/nologin/faq-email`,
      method: 'POST',
      body: mes,
      params: null
    };

    return this.httpClient.post<any>(`${environment.bffUrl}/data/nologin`, requestModel, { headers })
      .pipe(
        tap(response => {
          this.transferState.set(FAQ_EMAIL_KEY, response);
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
